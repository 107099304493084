import React, { FC } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { siteLinks } from '@agria/theme/src/const/siteLinks';
import { Container } from '../../container/Container';
import { createMarkup, useBackgroundColor } from '../../../utils';
import { Typography } from '../../typography/Typography';
import { LogoInverse, SslSecure, LogoIE } from '../../svg';
import { Stack } from '../../stack/Stack';
import Divider from '../../divider/Divider';
import { useSiteCountry } from '../../../hooks/useSiteCountry';
import { LinkTo } from '../../linkTo/LinkTo';

// Interface

export interface SimpleFooterProps {
  footerTerms: string;
}

// Styles

const StyledSimpleFooter = styled.div`
  ${({ theme: { space } }) => css`
    ${useBackgroundColor('brandOne100')};
    padding: ${space.fluidXxLarge} 0;
  `};
`;

const StyledSmallLinks = styled.ul`
  ${({ theme: { space, media, colors } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${space.xxSmall};

    li {
      a {
        text-decoration: none;
        color: ${colors.brandOne20};

        &:hover,
        &:focus {
          color: ${colors.utilityWhite};
        }
      }
    }

    @media (min-width: ${media.large}) {
      flex-direction: row;
      gap: ${space.small};

      li {
        + li {
          border-left: solid 1px ${colors.brandOne80};
          padding-left: ${space.small};
        }
      }
    }
  `};
`;

const StyledSslSecureContainer = styled.div`
  ${({ theme: { media } }) => css`
    width: 125px;

    @media (min-width: ${media.small}) {
      width: 167px;
    }
  `};
`;

// JSX test

export const SimpleFooter: FC<SimpleFooterProps> = ({ footerTerms }) => {
  const { isIe } = useSiteCountry();
  let secondaryNavigation;

  if (isIe) {
    secondaryNavigation = [
      {
        label: 'Terms and conditions',
        url: `${siteLinks.importantDocuments}`,
      },
      {
        label: 'Terms of business',
        url: `${siteLinks.importantDocuments}`,
      },
      {
        label: 'Privacy policy',
        url: `${siteLinks.importantDocuments}`,
      },
    ];
  }

  return (
    <StyledSimpleFooter>
      <Container>
        <Stack gap="large">
          {isIe ? <LogoIE aria-hidden="true" /> : <LogoInverse aria-hidden="true" />}
          <Divider inverse aria-hidden="true" />
          {secondaryNavigation && (
            <StyledSmallLinks>
              {secondaryNavigation?.map((nav, index) =>
                nav.url ? (
                  <Typography component="li" variant="bodySmall" key={index}>
                    <LinkTo to={nav.url}>{nav.label}</LinkTo>
                  </Typography>
                ) : null
              )}
            </StyledSmallLinks>
          )}
          <Typography
            variant="bodySmall"
            color="brandOne20"
            component="div"
            suppressHydrationWarning
          >
            {createMarkup(footerTerms)}
          </Typography>
          <StyledSslSecureContainer>
            <SslSecure title="SSL Secure logo" />
          </StyledSslSecureContainer>
        </Stack>
      </Container>
    </StyledSimpleFooter>
  );
};

export default SimpleFooter;
